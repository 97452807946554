<template>
  <base-section id="about-our-product">
    <v-layout child-flex>
      <v-data-table
          :headers="headers"
          :items="files"
          item-key="reference"
          class="elevation-1"
          :search="search"
          :custom-filter="filterOnlyCapsText"
      >
        <template v-slot:item.files.fr="{ item }">
          <p v-if="item.files.fr !== undefined && item.files.fr.description">
            <a :href="getUrl(item.files.fr._id)">{{ item.files.fr.description }}</a>
            <v-btn
                class="ml-2"
                icon
                dark
                small
                color="red"
                @click="removeFile(item.files.fr._id)"
            >
              <font-awesome-icon icon="trash" />
            </v-btn>
          </p>
          <a v-else-if="item.files.fr !== undefined" :href="getUrl(item.files.fr._id)"> Download </a>
          <p v-else />
        </template>

        <template v-slot:item.files.en="{ item }">
          <span v-if="item.files.en !== undefined && item.files.en.description">
            <a :href="getUrl(item.files.en._id)">{{ item.files.en.description }}</a>
            <v-btn
                class="ml-2"
                icon
                dark
                small
                color="red"
                @click="removeFile(item.files.en._id)"
            >
          <font-awesome-icon icon="trash" />
        </v-btn>

          </span>
          <p v-else />
        </template>

        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
          ></v-text-field>
        </template>
      </v-data-table>
    </v-layout>




  </base-section>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("fetchModels");
  },

  data() {
    return {
      search: ""
      // files: [
      //   { _id: "0", name: "MA_T41", fr: "1234", en: "1234" },
      //   { _id: "1", name: "MA_T46", fr: "1234", en: "1234" },
      //   { _id: "1", name: "EA1120", fr: "1234" }
      // ]
    };
  },
  computed: {
    files() {
      return this.$store.getters.models;
    },

    headers() {
      return [
        {
          text: "Modèle",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "fr",
          value: "files.fr"
        },
        {
          text: "en",
          value: "files.en"
        }
      ];
    }
  },

  methods: {
    getUrl(fileId) {
      return "/files/" + fileId;
    },
    removeFile(fileId) {
      this.$store.dispatch('deleteFile', fileId);
    },
    filterOnlyCapsText(value, search) {
      return (
          value != null &&
          search != null &&
          typeof value === "string" &&
          value
              .toString()
              .toLocaleUpperCase()
              .indexOf(search) !== -1
      );
    }
  }
};
</script>
